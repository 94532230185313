import React from "react"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

const Title = styled.h1`
  text-align: center;
  max-width: 550px;
  padding: 96px 10px 0 10px;
  margin: 0;
`

const Hero = () => (
  <Container>
    <Title>Doctors creating a happier and more stress free world for all</Title>
    <a
      href="#register"
      css={`
        margin-top: 52px;
        padding: 15px 25px;
        border-radius: 5px;
        background: ${props => props.theme.color.primary};
        border: none;
        color: ${props => props.theme.color.white.regular};
      `}
    >
      Find out more
    </a>
  </Container>
)

export default Hero
