import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export const portraitBreakpoint = "500px"

const Container = styled.div`
  display: flex;
  align-items: center;
  box-shadow: 2px 4px 5px 1px rgba(221, 219, 219, 0.5);
  border-radius: 11px 11px 11px 11px;
  margin-bottom: 20px;
  height: 160px;

  @media (min-width: ${portraitBreakpoint}) {
    flex-direction: column;
    justify-content: space-between;
    height: auto;
    min-width: 200px;
    padding-bottom: 20px;
  }
`

const StyledImage = styled(Img)`
  border-radius: 11px 0 0 11px;

  @media (min-width: ${portraitBreakpoint}) {
    border-radius: 11px 11px 0 0;
  }
`

const Name = styled.h4`
  margin-bottom: 20px;
`

const Title = styled.p`
  text-align: center;
  margin: 0;

  @media (min-width: ${props => props.theme.screen.tablet}) {
    ${props => props.theme.font_size.small}
    max-width: 240px;
  }
`

const FaceCard = ({ name, title, blurb, linkedIn, image }) => {
  return (
    <Container>
      <div
        css={`
          min-width: 160px;
          @media (min-width: ${portraitBreakpoint}) {
            min-width: 0;
            width: 100%;
          }
        `}
      >
        <StyledImage fluid={image} loading={"eager"} />
      </div>
      <div
        css={`
          display: flex;
          align-items: center;
          flex-direction: column;
          height: 100%;
          width: 100%;
        `}
      >
        <Name>{name}</Name>
        <Title>
          {title}
          <br />
          {blurb}
        </Title>
        <div
          css={`
            font-size: 30px;
            margin-top: 20px;
            @media (min-width: ${props => props.theme.screen.tablet}) {
              font-size: 35px;
            }
          `}
        >
          <a href={linkedIn} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon
              icon={["fab", "linkedin"]}
              title={`linkedin-${name}`}
            />
          </a>
        </div>
      </div>
    </Container>
  )
}

export default FaceCard
