import React from "react"

import BackgroundImage from "./backgroundImage"
import Nav from "./nav"
import Hero from "./hero"

const HeaderIndex = () => (
  <header
    css={`
      height: 568px;
    `}
  >
    <BackgroundImage />
    <Nav />
    <Hero />
  </header>
)

export default HeaderIndex
