import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export const portraitBreakpoint = "500px"

const Container = styled.div`
  display: flex;
  align-items: center;
  box-shadow: 2px 4px 5px 1px rgba(221, 219, 219, 0.5);
  border-radius: 11px 11px 11px 11px;
  margin-bottom: 16px;
  height: 160px;

  @media (min-width: ${portraitBreakpoint}) {
    flex-direction: column;
    justify-content: space-between;
    height: auto;
    min-width: 23%;
    max-width: 23%;
    padding-bottom: 20px;
  }
`

const Name = styled.h4`
  margin-bottom: 16px;

  @media (min-width: ${portraitBreakpoint}) {
    ${props => props.theme.font_size.xsmall}
  }

  @media (min-width: ${props => props.theme.screen.tablet}) {
    ${props => props.theme.font_size.small}
  }
`

const Title = styled.p`
  text-align: center;
  padding: 0 10px;
  margin: 0;

  @media (min-width: ${portraitBreakpoint}) {
    padding: 0 10px 16px 10px;
  }

  @media (min-width: ${props => props.theme.screen.tablet}) {
    ${props => props.theme.font_size.xxsmall}
  }

  @media (min-width: ${props => props.theme.screen.desktop}) {
    ${props => props.theme.font_size.xsmall}
    padding: 0 20px;
  }
`

const FaceCardSmall = ({ name, title, linkedIn }) => {
  return (
    <Container>
      <div
        css={`
          display: flex;
          align-items: center;
          flex-direction: column;
          height: 100%;
          width: 100%;
        `}
      >
        <Name>{name}</Name>
        <Title>{title}</Title>
        <div
          css={`
            font-size: 30px;
            margin-top: 16px;

            @media (min-width: ${portraitBreakpoint}) {
              margin-top: auto;
              padding-top: 16px;
            }

            @media (min-width: ${props => props.theme.screen.tablet}) {
              font-size: 35px;
            }
          `}
        >
          {linkedIn &&
          <a href={linkedIn} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon
              icon={["fab", "linkedin"]}
              title={`linkedin-${name}`}
            />
          </a>}
        </div>
      </div>
    </Container>
  )
}

export default FaceCardSmall
