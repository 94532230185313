import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import MeetTheTeam from "../components/meetTheTeam/meetTheTeam"
import Register from "../components/register/register"
import HeaderIndex from "../components/header/headerIndex"

const IndexPage = () => (
  <Layout header={<HeaderIndex />}>
    <SEO title="Home" />
    <MeetTheTeam />
    <Register />
  </Layout>
)

export default IndexPage
