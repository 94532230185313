import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import FaceCard, { portraitBreakpoint } from "./faceCard"
import FaceCardSmall from "./faceCardSmall"

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: ${props => props.theme.color.white.regular};
  padding: 40px 0 40px 0;
  @media (min-width: ${props => props.theme.screen.tablet}) {
    padding: 50px 0 80px 0;
  }
`

const TeamContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  max-width: 890px;
  width: 95%;
  margin-bottom: 30px;

  @media (min-width: ${portraitBreakpoint}) {
    flex-direction: row;
    margin-bottom: 50px;
    width: 100%;
    padding: 10px;
  }
`

const AdvisorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 950px;
  width: 95%;

  @media (min-width: ${portraitBreakpoint}) {
    flex-direction: row;
    width: 100%;
    padding: 10px;
  }
`

const MeetTheTeam = () => {
  const data = useStaticQuery(graphql`
    query {
      buzzy: file(relativePath: { eq: "team/buzzy.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      liam: file(relativePath: { eq: "team/liam.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      chris: file(relativePath: { eq: "team/chris.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      justin: file(relativePath: { eq: "team/justin.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      liz: file(relativePath: { eq: "team/liz.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gareth: file(relativePath: { eq: "team/gareth.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      roshni: file(relativePath: { eq: "team/roshni.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Container id="meet-the-team">
      <h2
        css={`
          margin: 0 0 20px 0;
        `}
      >
        Meet the team
      </h2>
      <p
        css={`
          max-width: 860px;
          margin: 0 0 45px 0;
          text-align: center;
          padding: 0 15px;
          @media (min-width: ${props => props.theme.screen.desktop}) {
            padding: 0;
          }
        `}
      >
        Our team was founded by experienced doctors, in collaboration with technology experts. We believe in creating tools to help everyone in society improve their wellbeing.
      </p>
      <TeamContainer>
        <FaceCard
          name="Farhana Sadek"
          title="CEO &amp; Co-founder"
          blurb="NHS GP"
          linkedIn="https://uk.linkedin.com/in/buzzy-sadek-a91876150"
          image={data.buzzy.childImageSharp.fluid}
        />
        <FaceCard
          name="Liam Smith"
          title="COO &amp; Co-founder"
          blurb="NHS GP"
          linkedIn="https://www.linkedin.com/in/liam-smith-a6a7a5172/"
          image={data.liam.childImageSharp.fluid}
        />
      </TeamContainer>
      <h3>Advisors</h3>
      <AdvisorContainer>
        <FaceCardSmall
          name="Michael Othen"
          title="Serial entrepreneur, creative director, designer & mobile game expert"
          linkedIn="https://www.linkedin.com/in/michaelothen/"
          isAdvisor
        />
        <FaceCardSmall
          name="Caitlin Clarke"
          title="Global Head of Strategy & Growth, Commercial Development at Financial Times"
          linkedIn="https://www.linkedin.com/in/caitlin-clarke-18419b25/"
          isAdvisor
        />
        <FaceCardSmall
          name="Neil Rothwell"
          title="Clinical Psychologist, NHS Scotland Mindfulness Co-Lead"
          isAdvisor
        />
        <FaceCardSmall
          name="Chris Harding"
          title="Tech leadership, Software development"
          linkedIn="https://www.linkedin.com/in/chris-harding-london/"
          isAdvisor
        />
      </AdvisorContainer>
    </Container>
  )
}

export default MeetTheTeam
