import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const BackgroundImage = () => {
  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "logo-grey.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div
      css={`
        display: none;
        position: absolute;
        left: -50px;
        width: 100%;
        max-width: 30%;
        opacity: 0.45;

        @media (min-width: 600px) {
          display: block;
          max-width: 30%;
        }

        @media (min-width: ${props => props.theme.screen.tablet}) {
          max-width: 25%;
        }
      `}
    >
      <Img fluid={data.background.childImageSharp.fluid} />
    </div>
  )
}

export default BackgroundImage
