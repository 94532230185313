import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const BackgroundImage = () => {
  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "background.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div
      css={`
        position: absolute;
        width: 100%;
        max-width: ${props => props.theme.screen.maxWidth};
        height: 568px;
        opacity: 0.44;
        z-index: -1;
      `}
    >
      <Img
        fluid={data.background.childImageSharp.fluid}
        style={{ height: "100%" }}
        loading={"eager"}
      />
    </div>
  )
}

export default BackgroundImage
