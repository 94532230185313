import React from "react"
import styled from "styled-components"

import BackgroundImage from "./backgroundImage"
import RegistrationForm from "./registrationForm"

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background-color: ${props => props.theme.color.secondary};
  position: relative;
  overflow: hidden;

  @media (min-width: ${props => props.theme.screen.tablet}) {
    padding: 90px;
  }
`

const Register = () => {
  return (
    <Container id="register">
      <BackgroundImage />
      <h3
        css={`
          margin: 0 0 15px 0;
        `}
      >
        Want to find out more?
      </h3>
      <p
        css={`
          margin: 0 0 35px 0;
          text-align: center;
          max-width: 240px;

          @media (min-width: 450px) {
            max-width: max-content;
          }
        `}
      >
        Please contact us at <a href="mailto: info@myvim.co">info@myvim.co</a>
      </p>
    </Container>
  )
}

export default Register
