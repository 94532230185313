import React, { useState } from "react"
import styled from "styled-components"

import { formEncode } from "../../utils/network"

const Input = styled.input`
  margin-bottom: 15px;
  padding: 15px 25px;
  border-radius: 5px;
  border: none;
  width: 100%;
`

const Button = styled.button`
  padding: 15px 25px;
  border-radius: 5px;
  background: ${props => props.theme.color.primary};
  border: none;
  color: ${props => props.theme.color.white.regular};
  width: 100%;
  cursor: pointer;
`

const RegistrationForm = () => {
  const [email, setEmail] = useState("")
  const [registered, setRegistered] = useState(false)

  const handleSubmit = e => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: formEncode({ "form-name": "register", email: email }),
    })
      .then(() => setRegistered(true))
      .catch(error => alert(error))

    e.preventDefault()
  }

  return (
    <form
      name="register"
      data-netlify="true"
      data-netlify-honeypot="sneaky-sneaky"
      onSubmit={handleSubmit}
    >
      <div
        css={`
          position: absolute;
          top: -9999px;
          left: -9999px;
        `}
      >
        <input type="hidden" name="form-name" value="register" />
        <input name="sneaky-sneaky" aria-label="sneaky" />
      </div>
      {registered ? (
        <p
          css={`
            font-weight: bold;
            margin: 0;
          `}
        >
          Thanks, we'll be in touch
        </p>
      ) : (
        <React.Fragment>
          <Input
            type="text"
            name="email"
            placeholder="Enter your email"
            aria-label="email address"
            onChange={e => setEmail(e.target.value)}
          ></Input>
          <Button type="submit">Register</Button>
        </React.Fragment>
      )}
    </form>
  )
}

export default RegistrationForm
